<template>
  <!-- 监控--- 场景管理 -->
  <div style="margin-top: 20px;">
    <div class="box-card">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="场景：">
          <el-input v-model="queryForm.scene_name" maxlength="30" @keyup.enter.native="onSubmit" placeholder="请输入场景名称"
            @input="(e) => (queryForm.scene_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="场景管理">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('a0d8f46b880d4a4fbb81b6db')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="addNewItem"
              v-if="this.getUserInfo.user_name != 'admin' && isShowBtn('4e9918f9a1e6487486ddce6a')">新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
           <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="monitor_count">
              <el-link type="primary" target="_blank" v-if="row.monitor_count > 0"
                  @click="openParking(row)">{{ row.monitor_count }}</el-link>
                <span v-else>{{ row.monitor_count }}</span>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="edit(row)"
                  v-if="isShowBtn('4532f0e0e67a4ea0bf0b462e')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="架构配置" @click="ArchitectureConfig(row)"
                  v-if="isShowBtn('39086dcb18984b1f9db036ca')"></lbButton>
                <lbButton type="info" icon="yulanpeizhi1" hint="预览配置" @click="previewConfig(row)"
                  v-if="isShowBtn('fa7759410e9f4b6183a0c7f1')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="scene_name" label="场景"></el-table-column>
            <el-table-column label="视频监控设备">
              <template slot-scope="scope">
                <el-link type="primary" target="_blank" v-if="scope.row.monitor_count > 0"
                  @click="openParking(scope.row)">{{ scope.row.monitor_count }}</el-link>
                <span v-else>{{ scope.row.monitor_count }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">{{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}</template>
            </el-table-column>
            <el-table-column label="操作" width="430">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="edit(scope.row)"
                  v-if="isShowBtn('4532f0e0e67a4ea0bf0b462e')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="架构配置" @click="ArchitectureConfig(scope.row)"
                  v-if="isShowBtn('39086dcb18984b1f9db036ca')"></lbButton>
                <lbButton type="info" icon="yulanpeizhi1" hint="预览配置" @click="previewConfig(scope.row)"
                  v-if="isShowBtn('fa7759410e9f4b6183a0c7f1')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增、编辑场景 -->
    <lebo-dialog append-to-body :title="title" :isShow="isAddVisible" width="30%" @close="closeAddVisible" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="场景名称：" prop="scene_name">
          <el-input v-model="addForm.scene_name" maxlength="30" placeholder="请输入场景名称"
            @input="(e) => (addForm.scene_name = validSpace(e))"></el-input>
        </el-form-item>
        <!-- '新增'场景所在位置选项 -->
        <el-form-item label="所在位置：" prop="position">
          <div class="button_box">
            <el-input style="margin-right: 15px;width: 71%;" v-model="addForm.position" disabled v-show="addForm.position"
              @input="(e) => (addForm.position = validSpace(e))"></el-input>
            <lbButton icon="zhongxuan" :fill="true" @click="openMapDialog">{{
              addForm.position ? "重新选择" :
              "地图标注" }}</lbButton>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click=" closeSenceDialog ">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click=" addAndEdit " v-preventReClick>保 存</lbButton>
      </span>
      <lebo-dialog append-to-body class="mapDialog" width="50%" title="选择地图坐标" :isShow=" addressInnerVisible "
        @close=" closeAddress " footerSlot>
        <div class="searchMap">
          <el-form :inline="true" size="small">
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.province_code" filterable clearable placeholder="请选择省" @change="provinceChange">
              <el-option v-for="item in provinceSelectList" :key="item.specific_code" :label="item.specific_name" :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.city_code" filterable clearable placeholder="请选择市" @change="cityChange">
              <el-option v-for="item in citySelectList" :key="item.specific_code" :label="item.specific_name" :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select popper-class="my-select" v-model="addressInfo.district_code" filterable clearable placeholder="请选择区" @change="districtChange">
              <el-option v-for="item in districtSelectList" :key="item.specific_code" :label="item.specific_name" :value="item.specific_code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字" @input="(e) => (keyword = validSpace(e))"></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-button size="small" type="primary" @click="getAdress">搜索</el-button>
          </el-form-item> -->
        </el-form>
        </div>
        <div id="amapall" class="all-amap"></div>
      </lebo-dialog>
    </lebo-dialog>
    <!-- 架构配置 -->
    <lebo-dialog append-to-body title="架构配置" class="pub_dialog" :isShow=" isArchitectureConfig " width="40%"
      @close=" closeArchitectureConfig() " footerSlot>
      <el-tree :data=" treeList " node-key="Id" :props=" customProps " default-expand-all :expand-on-click-node=" false ">
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div v-text=" node.label "></div>
          <div>
            <i class="iconfont lebo-xinzeng" @click=" isAddtree(data) "></i>
            <i class="iconfont lebo-bianji" @click=" isEditTree(data) "></i>
            <i class="iconfont lebo-shanchu1" @click=" remove(data) "></i>
            <!-- <el-button type="text" size="mini" @click="isAddtree(data)" style="color: #2499a3;"> 新增 </el-button>
            <el-button type="text" size="mini" @click="isEditTree(data)" v-show="node.level != 1"
              style="color: #8E44AD;"> 编辑 </el-button>
            <el-button type="text" size="mini" @click="remove(data)" v-show="node.level != 1" style="color: #ff0000;">
            删除 </el-button>-->
          </div>
        </div>
      </el-tree>
      <!-- 新增类别 -->
      <lebo-dialog append-to-body width="30%" title="新增类别" :isShow=" isAddTreeVisible " @close=" delForm " footerSlot>
        <el-form :model=" addTreeForm " :rules=" addTreeFormRules " ref="addTreeFormRef" label-width="100px"
          class="demo-ruleForm">
          <el-form-item label="上一级：">
            <el-input v-model=" addTreeForm.parent_name " disabled></el-input>
          </el-form-item>
          <el-form-item label="类别名称：" prop="category_name">
            <el-input maxlength="15" v-model=" addTreeForm.category_name " placeholder="请输入类别名称"
              @input=" (e) => (addTreeForm.category_name = validSpace(e)) "></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- <lbButton type="goBack" icon="back" @click=" isAddTreeVisible = false ">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm" @click=" append() " v-preventReClick>保 存</lbButton>
        </span>
      </lebo-dialog>
      <!-- 编辑类别 -->
      <lebo-dialog append-to-body width="30%" title="编辑类别" :isShow=" isEditTreeVisible " @close=" editTreeFormClose "
        footerSlot>
        <el-form :model=" editTreeForm " ref="editTreeFormRef" :rules=" editTreeFormRules " label-width="100px"
          class="demo-ruleForm">
          <el-form-item label="类别名称：" prop="category_name">
            <el-input v-model=" editTreeForm.category_name " maxlength="15" placeholder="请输入类别名称"
              @input=" (e) => (editTreeForm.category_name = validSpace(e)) "></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- <lbButton type="goBack" icon="back" @click=" isEditTreeVisible = false ">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm" @click=" editTree() " v-preventReClick>保 存</lbButton>
        </span>
      </lebo-dialog>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="default" fill icon="back" @click=" isArchitectureConfig = false ">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click=" isArchitectureConfig = false ">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看设置 -->
    <lebo-dialog append-to-body title="视频监控设备" :isShow=" dialogStuatus " width="30%" @close=" dialogStuatus = false "
      footerSlot closeOnClickModal>
      <el-table :data=" recorderDialogList " border stripe class="table_box" height="460">
        <el-table-column header-align="center" align="center" type="index" label="序号" width="100"></el-table-column>
        <el-table-column header-align="center" align="center" prop="ChannelName" label="设备名称"></el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 预览配置 -->
    <lebo-dialog title="预览配置" width="60%" :isShow=" lookPreviewConfig " :footerSlot=" true "
      @close=" lookPreviewConfig = false " >
      <lookPreviewConfig v-if=" lookPreviewConfig " :id=" dialogPreviewConfigId " :scene_name=" dialogPreviewConfigName" @closeDialog=" closePreviewConfig"  ></lookPreviewConfig>
    </lebo-dialog>
  </div>
</template>
<script>
import {
  getSceneList,
  getEquipmentList,
  AddScene,
  Delscene,
  getSceneTreeDetail,
  addCategory,
  updateCategory,
  delCategory,
  setSceneTreeDetail
} from '@/api/monitoring'
import { getProvinceList } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addressjs from '@/utils/city.js'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import lookPreviewConfig from './components/lookPreviewConfig/index.vue'
export default {
  components: { lookPreviewConfig },
  data () {
    return {
      // 查询表单
      queryForm: {
        scene_name: '',
        PageSize: 10,
        PageIndex: 1,
        position: '',
        longitude: '',
        latitude: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      // 总数量
      total: 0,
      // 表格列表
      tableData: [],
      multipleSelection: [],
      // 是否展示新增对话框
      isAddVisible: false,
      title: '',
      // 新增表单
      addForm: {
        scene_id: '',
        Id: '',
        scene_name: '',
        position: '',
        longitude: '',
        latitude: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      addFormRules: {
        scene_name: [
          { required: true, message: '请输入场景名称', trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请标注所在位置', trigger: 'change' }
        ]
      },
      // 是否展示架构配置对话框
      isArchitectureConfig: false,
      // 树控件列表
      treeList: [],
      customProps: {
        label: 'Name',
        children: 'children'
      },
      // 添加类别 对话框
      isAddTreeVisible: false,
      addTreeForm: {
        parent_name: '',
        scene_id: '', // 主场景id
        category_name: '', // 组织架构名称
        level: 1, // 级别
        parent_id: '' // 1级以上的父级id
      },
      addTreeFormRules: {
        category_name: [
          { required: true, message: '请输入类别名称', trigger: 'blur' }
        ]
      },
      // 编辑类别
      isEditTreeVisible: false,
      editTreeForm: {
        Id: '',
        category_name: ''
      },
      editTreeFormRules: {
        category_name: [
          { required: true, message: '请输入类别名称', trigger: 'blur' }
        ]
      },
      dialogStuatus: false,
      recorderDialogList: [],
      addressInnerVisible: false, // 地理位置选择弹框
      keyword: '',
      geocoder: {},
      infoWindow: {},
      marker: {},
      lookPreviewConfig: false, // 预览配置弹框
      dialogPreviewConfigId: '',
      dialogPreviewConfigName: '',
      addressInfo: {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      provinceSelectList: [], // 省份下拉列
      citySelectList: [], // 市下拉列
      districtSelectList: [], // 区下拉列
      autocomplete: null,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '场景',
            prop: 'scene_name'
          }, {
            label: '视频监控设备',
            prop: 'monitor_count',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {
    'addressInnerVisible' (newValue, oldValue) {
      var that = this
      if (newValue) {
        that.initMap()
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.fnGetSceneList()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取场景集合
    async fnGetSceneList () {
      const res = await getSceneList(this.queryForm)
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetSceneList()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetSceneList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetSceneList()
    // },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetSceneList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetSceneList()
    // },
    // 当表格选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 删除
    batchDel () {
      console.log(this.multipleSelection)
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的场景, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i].Id)
              if (arr.length === this.multipleSelection.length) {
                obj.Id = arr.join(',')
                this.fnDelItem(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除！')
          })
      } else {
        this.$msg.warning('请选择要删除的场景！')
      }
    },
    // 删除场景请求
    async fnDelItem (obj) {
      console.log(obj)
      const res = await Delscene(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetSceneList()
    },
    // 新增场景对话框
    addNewItem () {
      this.addForm.Id = ''
      this.addForm.scene_name = ''
      this.addForm.position = ''
      this.addForm.latitude = ''
      this.addForm.longitude = ''
      this.isAddVisible = true
      this.title = '新增场景'
    },
    // 编辑场景对话框
    edit (item) {
      this.addForm.Id = item.Id
      this.addForm.scene_name = item.scene_name
      this.addForm.position = item.position
      this.addForm.latitude = item.latitude
      this.addForm.longitude = item.longitude
      this.isAddVisible = true
      this.title = '编辑场景'
    },
    // 确定新增或编辑
    addAndEdit () {
      console.log('提交')
      // 验证表单
      this.$refs.addFormRef.validate(valid => {
        if (valid) {
          var obj = {}
          obj.scene_name = this.addForm.scene_name
          obj.position = this.addForm.position // 场景地点
          obj.latitude = this.addForm.latitude // 场景经纬度
          obj.longitude = this.addForm.longitude
          if (this.title === '新增场景') {
            this.fnAddScene(obj)
          } else {
            obj.Id = this.addForm.Id
            this.fnAddScene(obj)
          }
        } else {
          return false
        }
      })
      this.closeSenceDialog()
    },
    // 新增/修改场景请求
    async fnAddScene (obj) {
      const res = await AddScene(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        if (obj.Id) {
          this.fnGetSceneList()
        } else {
          this.onSubmit()
        }
      }
      this.isAddVisible = false
    },
    // 关闭对话框重置数据 --> 新增编辑
    closeAddVisible () {
      this.$refs.addFormRef.resetFields()
      this.addForm.scene_name = ''
      this.addForm.Id = ''
      this.isAddVisible = false
    },
    // 展示架构配置对话框 --> 获取树控件
    async ArchitectureConfig (item) {
      this.treeList = []
      this.fnGetTreeList(item.Id)
      this.isArchitectureConfig = true
    },
    async fnGetTreeList (id) {
      var arr = []
      const res = await getSceneTreeDetail({
        Id: id
      })
      // console.log(res)
      if (res && res.Code === 200) {
        arr.push(res.Data)
        this.treeList = arr
      } else {
        this.treeList = []
      }
    },
    // 关闭 新增类别或编辑类别 时，重置数据
    delForm () {
      this.isAddTreeVisible = false
      this.$refs.addTreeFormRef.resetFields()
      // console.log(this.addTreeForm)
    },
    // 打开新增类别对话框
    isAddtree (data) {
      console.log('打开新增类别对话框--', data)
      this.isAddTreeVisible = true
      this.addTreeForm.scene_id = data.scene_id ? data.scene_id : data.Id
      this.addTreeForm.parent_name = data.Name
      this.addTreeForm.level = data.level ? data.level + 1 : 1
      this.addTreeForm.parent_id = data.level >= 1 ? data.Id : ''
    },
    // 新增树控件类别
    async append () {
      // 验证表单
      this.$refs.addTreeFormRef.validate(async valid => {
        if (valid) {
          const res = await addCategory({
            scene_id: this.addTreeForm.scene_id,
            category_name: this.addTreeForm.category_name,
            level: this.addTreeForm.level,
            parent_id: this.addTreeForm.parent_id
          })
          if (res && res.Code === 200) {
            this.fnGetTreeList(this.addTreeForm.scene_id)
            this.isAddTreeVisible = false
          }
        } else {
          return false
        }
      })
    },
    // 打开编辑类别对话框
    isEditTree (data) {
      console.log(data)
      this.editTreeForm.Id = data.Id
      this.editTreeForm.category_name = data.Name
      this.editTreeForm.scene_id = data.scene_id
      this.isEditTreeVisible = true
    },
    editTreeFormClose () {
      this.isEditTreeVisible = false
      this.$refs.editTreeFormRef.resetFields()
    },
    editTree () {
      // 验证表单
      this.$refs.editTreeFormRef.validate(async valid => {
        if (valid) {
          console.log(this.editTreeForm)
          const res = await updateCategory({
            Id: this.editTreeForm.Id,
            category_name: this.editTreeForm.category_name
          })
          if (res && res.Code === 200) {
            this.fnGetTreeList(this.editTreeForm.scene_id)
            this.isEditTreeVisible = false
          }
        } else {
          return false
        }
      })
    },
    // 生成随机数
    // randomString() {
    //   var e = 24;
    //   var t = "0123456789abcdefghijklmnopqrstuvwsyz";
    //   var a = t.length;
    //   var n = "";
    //   // console.log(a);
    //   for (var i = 0; i < e; i++) {
    //     n += t.charAt(Math.floor(Math.random() * a));
    //   }
    //   return n;
    // },
    // // 展示 新增类别对话框
    // isAddtree(data) {
    //   this.treeForm = data;
    //   // console.log(this.treeForm);
    //   this.isTreeVisible = true;
    // },
    // // 展示修改类别对话框
    // isEditTree(data) {
    //   this.treeForm = data;
    //   // console.log(this.treeForm);
    //   this.isVisible = true;
    // },
    // // 新增 树控件类别
    // append() {
    //   var data = this.treeForm
    //   if (!data.son_name) {
    //     this.$msg.info('输入不能为空')
    //     return
    //   }
    //   //  // console.log(this.treeForm)
    //   // this.forEachTree(this.treeList,  this.treeForm.son_name)
    //   var arr = []
    //   var list = this.forEachTree(this.treeList, arr)
    //   for (var i = 0; i < list.length; i++) {
    //     if (list[i] === this.treeForm.son_name) {
    //       this.$msg.info('不能添加重复的类别')
    //       return
    //     }
    //   }
    //   // console.log( this.forEachTree(this.treeList, arr))
    //   const newChild = {
    //     _id: this.randomString(),
    //     scene_name: data.son_name,
    //     monitor_id: [],
    //     monitor_count: 0,
    //     children: [],
    //     level: data.level + 1,
    //   };
    //   if (!data.children) {
    //     this.$set(data, "children", []);
    //   }
    //   data.children.push(newChild);
    //   this.isTreeVisible = false;
    // },
    // forEachTree(data, arr) {
    //   var _this = this
    //   if (!data) {
    //     return;
    //   }
    //   for (var i = 0; i < data.length; i++) {
    //     arr.push(data[i].scene_name)
    //     if (data[i].children && data[i].children.length > 0) {
    //       _this.forEachTree(data[i].children, arr)
    //     }
    //   }
    //   return arr
    // },
    // 删除 树控件类别
    remove (data) {
      console.log(data)
      this.$confirm('是否确认删除此类别，删除后将无法恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await delCategory({
            Id: data.Id
          })
          if (res && res.Code === 200) {
            this.fnGetTreeList(data.scene_id)
          }
          // const parent = node.parent;
          // const children = parent.data.children || parent.data;
          // const index = children.findIndex((d) => d._id === data._id);
          // children.splice(index, 1);
        })
        .catch(() => {
          this.$msg.info('已取消删除！')
        })
    },
    // // 编辑 树控件类别
    // editTree(data) {
    //   // console.log(this.treeList);
    //   this.isVisible = false;
    // },
    // // 完成树控件类别修改
    // postTree() {
    //   this.isArchitectureConfig = false;
    //   var that = this;
    //   // console.log(that.treeList);
    //   var obj = {}
    //   obj._id = that.treeList[0]._id
    //   obj.list = that.treeList[0].children
    //   this.fnsetSceneTreeDetail(obj)
    // },
    // async fnsetSceneTreeDetail(obj) {
    //   const res = await setSceneTreeDetail(obj)
    //   if (res && res.Code === 200) {
    //     this.$msg.success(res.Message)
    //   }
    // },
    // 预览配置
    previewConfig (item) {
      if (item.monitor_count > 0) {
        this.lookPreviewConfig = true
        this.dialogPreviewConfigId = item.Id
        this.dialogPreviewConfigName = item.scene_name
        // this.$router.push(`/preview-config/${item.scene_name}/${item.Id}`)
      } else {
        this.$msg.info('目前没有可配置预览的设备，请先添加配置设备')
      }
    },
    // 查看视频监控设备
    async openParking (item) {
      const res = await getEquipmentList({ SceneId: item.Id })
      console.log('openParking----', res)
      if (res.Code === 200 && res.Data && res.Data.length > 0) {
        this.dialogStuatus = true
        this.recorderDialogList = res.Data
      } else {
        this.recorderDialogList = []
      }
      // console.log(this.recorderDialogList);
    },
    // 关闭地图对话框
    closeAddress () {
      this.keyword = ''
      this.addressInnerVisible = false
    },
    // // 获取搜索位置
    getAdress () {
      const that = this
      this.geocoder.getLocation(that.keyword, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location
          that.sureLnglatMarker(lnglat)
        }
      })
    },
    sureLnglatMarker (lnglat) {
      var that = this
      var specialPoint = ['北京市', '上海市', '天津市', '重庆市']
      var oneCountry_twoSystems = ['台湾省', '澳门特别行政区', '香港特别行政区']
      that.geocoder.getAddress(lnglat, function (status, result) {
        // console.log('=-=-=-=getAddress=', result, status)
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, adcode } = result.regeocode.addressComponent
          that.addForm.province = province
          that.addForm.city = city
          that.addForm.district = district
          that.addForm.province_code = addressjs.province_list.find(item => item.name === province).code
          //
          that.addressInfo.province = province
          that.addressInfo.city = city
          that.addressInfo.district = district
          that.addressInfo.province_code = addressjs.province_list.find(
            (item) => item.name === province
          ).code
          // 获取市区下拉列
          that.fngetProvinceList({
            specific_level: 2,
            specific_code: that.addressInfo.province_code
          }, 2)
          if (city === '') {
            if (specialPoint.indexOf(province) > -1) { // 直辖市
              that.addForm.city = province
              // 直辖市
              that.addressInfo.city = province
            } else if (adcode.startsWith('4690') || adcode.startsWith('6590')) { // 海南省 青海省
              that.addForm.city = district
              // 海南省 青海省
              that.addressInfo.city = district
            } else if (oneCountry_twoSystems.indexOf(province) > -1) { // 台湾 香港 澳门
              that.addForm.province = province
              that.addForm.city = province
              that.addForm.district = province
              // 台湾 香港 澳门
              that.addressInfo.province = province
              that.addressInfo.city = province
              that.addressInfo.district = province
            }
          }
          if (that.addForm.city === '') {
            // 有部分地区的province === "中华人民共和国"；
            return
          }
          // console.log('-=========that.addForm.city=', that.addForm.city)
          that.addForm.city_code = addressjs.city_list.find(item => item.name === that.addForm.city).code
          that.addForm.district_code = addressjs.district_list.find(item => item.name === that.addForm.district).code
          that.addressInfo.city_code = addressjs.city_list.find(
            (item) => item.name === that.addressInfo.city
          ).code
          // 获取区级下拉列
          that.fngetProvinceList({
            specific_level: 3,
            specific_code: that.addressInfo.city_code
          }, 3)
          that.addressInfo.district_code = addressjs.district_list.find(
            (item) => item.name === that.addressInfo.district
          ).code
          // console.log("-=-=-=addForm-=-", JSON.stringify(that.addForm));
          var address = result.regeocode.formattedAddress
          that.addressInfo.address = address
          that.addressInfo.longitude = lnglat.lng
          that.addressInfo.latitude = lnglat.lat
          that.showInfoWindow(lnglat, address)
        }
      })
    },
    showInfoWindow (lnglat, address) {
      var infoDiv = document.createElement('div')
      infoDiv.className = 'infodiv'
      var addressspan = document.createElement('span')
      addressspan.className = 'addressspan'
      addressspan.innerText = address
      infoDiv.appendChild(addressspan)
      var confirmbtn = document.createElement('input')
      confirmbtn.className = 'input-btn'
      confirmbtn.type = 'button'
      confirmbtn.value = '确定'
      var that = this
      confirmbtn.onclick = function () {
        that.addForm.position = address
        that.addForm.longitude = lnglat.lng
        that.addForm.latitude = lnglat.lat
        that.addressInnerVisible = false
      }
      infoDiv.appendChild(confirmbtn)
      this.infoWindow.setContent(infoDiv)
      this.map.add(this.marker)
      this.map.setCenter(lnglat)
      this.marker.setPosition(lnglat)
      this.infoWindow.open(this.map, this.marker.getPosition())
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.map = new AMap.Map('amapall', {
          zoom: 12
        })
        this.map.clearMap()
        if (that.addForm.longitude && that.addForm.latitude) {
          that.map.setCenter([that.addForm.longitude, that.addForm.latitude])
        }
        this.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30)
        })
        this.marker = new AMap.Marker()
        that.map.plugin(['AMap.Geocoder'], () => {
          that.geocoder = new AMap.Geocoder({
            radius: 1000 // 范围，默认：500
          })
          if (that.addForm.position) {
            that.geocoder.getLocation(that.addForm.position, function (status, result) {
              if (status === 'complete' && result.geocodes.length) {
                var lnglat = result.geocodes[0].location
                that.showInfoWindow(lnglat, that.addForm.position)
              }
            })
          }
        })
        this.map.on('click', (e) => {
          that.sureLnglatMarker(e.lnglat)
        })
        AMap.event.addListener(that.marker, 'click', function () {
          // console.log("=-=-=-=-=markerclick");
          that.infoWindow.open(that.map, that.marker.getPosition())
        })
        // 定位
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: false, // 定位成功后是否自动调整地图视野到定位点
            showCircle: false // 是否显示定位精度圈
          })
          that.map.addControl(geolocation)
          // geolocation.getCityInfo(function (status, result) {
          //   console.log('定位中')

          //   if (status === 'complete') {
          //     console.log('定位成功', result)

          //     // onComplete(result)
          //   } else {
          //     console.log('定位失败', result)

          //     // onError(result)
          //   }
          // })
          geolocation.getCurrentPosition(function (status, result) {
            console.log('定位中...')
            if (status === 'complete') {
              console.log('定位成功')

              // onComplete(result)
            } else {
              console.log('定位失败', result)

              // onError(result)
            }
          })
        })
        // 输入提示
        that.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          that.autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: that.map
          }) // 构造地点查询类
          AMap.event.addListener(that.autocomplete, 'select', select)// 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            if (e.poi.location === '') {
              that.geocoder.getLocation(`${e.poi.district}${e.poi.name}${e.poi.address}`, function (status, result) {
                if (status === 'complete' && result.geocodes.length) {
                  var lnglat = result.geocodes[0].location
                  that.sureLnglatMarker(lnglat)
                }
              })
            } else {
              that.sureLnglatMarker(e.poi.location)
            }
          }
        })
      })
    },
    // 关闭新增编辑场景对话框
    closeSenceDialog () {
      this.isAddVisible = false
      this.addForm = {
        scene_id: '',
        Id: '',
        scene_name: '',
        position: '',
        longitude: '',
        latitude: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      }
    },
    // 关闭架构配置
    closeArchitectureConfig () {
      this.treeList = []
      this.isArchitectureConfig = false
    },
    // 关闭预览配置
    closePreviewConfig () {
      this.lookPreviewConfig = false
      this.fnGetSceneList()
    },
    // 打开地图对话框
    openMapDialog () {
      this.addressInnerVisible = true
      this.position = this.addForm.position
      this.fngetProvinceList({
        specific_level: 1
      }, 1)
      if (this.addForm.province_code) {
        // 获取市下拉列表
        this.fngetProvinceList({
          specific_level: 2,
          specific_code: this.addForm.province_code
        }, 2)
      }
      if (this.addForm.city_code) {
        // 获取区下拉列表
        this.fngetProvinceList({
          specific_level: 3,
          specific_code: this.addForm.city_code
        }, 3)
      }
      this.addressInfo.longitude = this.addForm.longitude
      this.addressInfo.latitude = this.addForm.latitude
      this.addressInfo.address = this.addForm.addres
      this.addressInfo.province = this.addForm.province
      this.addressInfo.province_code = this.addForm.province_code
      this.addressInfo.city = this.addForm.city
      this.addressInfo.city_code = this.addForm.city_code
      this.addressInfo.district = this.addForm.district
      this.addressInfo.district_code = this.addForm.district_code
      this.initMap()
    },
    // 省下拉列发生改变触发
    provinceChange (val) {
      console.log('provinceChange', val)
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.province = ''
        this.addressInfo.city = ''
        this.addressInfo.city_code = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      };
      // 清空市区
      this.addressInfo.city = ''
      this.addressInfo.city_code = ''
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.provinceSelectList.find(item => item.specific_code === val)
      this.addressInfo.province = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      // 获取市下拉列表
      this.fngetProvinceList({
        specific_level: 2,
        specific_code: val
      }, 2)
    },
    // 市下拉列发生改变触发
    cityChange (val) {
      if (!val) {
        this.addressInfo.city = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      }
      // 清空区级数据
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.citySelectList.find(item => item.specific_code === val)
      this.addressInfo.city = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      this.autocomplete.setCity(val)
      // 获取区级下拉列表
      this.fngetProvinceList({
        specific_level: 3,
        specific_code: val
      }, 3)
    },
    // 区下拉列发生改变触发
    districtChange (val) {
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.district = ''
        return
      };
      // 筛选出符合条件的数据
      var item = this.districtSelectList.find(item => item.specific_code === val)
      this.addressInfo.district = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
    },
    // 获取省市区列表
    async fngetProvinceList (params, type) {
      // console.log('获取省市区列表');
      // type: 1 省；2 市；3 区
      const res = await getProvinceList(params)
      console.log('fngetProvinceList', res)
      if (type === 1) {
        this.provinceSelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 2) {
        this.citySelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 3) {
        this.districtSelectList = res && res.Code === 200 ? res.Data : []
      }
    }
  }
}
</script>

<style scoped lang="less">
.custom-tree-node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  &:hover {
    .iconfont {
      display: inline-block;
    }
  }

  .iconfont {
    display: none;
    margin-left: 10px;
  }
}

.table_box {
  /deep/ .el-table__body-wrapper {
    max-height: 431px;
    overflow-y: auto;
  }
}

.el-tree {
  max-height: 500px;
  overflow: auto;
}

.mapDialog {
  /deep/ .el-dialog__body {
    padding: 0;
    position: relative;
  }

  .searchMap {
    display: flex;
    justify-content: start;
    margin: 15px 0 15px 15px;
    position: absolute;
        top: 10px;
        left: 10px;
        z-index: 99;
        .el-select.el-select--small {
          width: 150px;
        }
    .el-input {
      width: 200px;
      margin-right: 15px;
    }
  }

  .all-amap {
    width: 100%;
    height: 500px;

    /deep/.amap-maps {
      .amap-drags {
        .amap-overlays {
          .amap-info {
            .amap-info-contentContainer {
              .amap-info-content {
                background: blanchedalmond;

                .infodiv {
                  display: flex;
                  width: 150px;
                  flex-direction: column;
                  align-items: flex-start;

                  .input-btn {
                    background: #409eff;
                    margin-top: 10px;
                    color: #ffffff;
                    font-weight: 400;
                    border: none;
                    border-radius: 2px;
                  }
                }
              }

              .amap-info-sharp {
                bottom: 0;
                left: 50%;
                margin-left: -8px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid blanchedalmond;
              }

              .amap-info-close {
                position: absolute;
                top: 5px;
                right: 5px !important;
              }
            }
          }
        }
      }
    }
  }
}

.button_box {
  .lb-button {
    margin-right: 0;
  }
}</style>
