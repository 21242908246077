<template>
  <!-- 预览场景配置 -->
  <div style="height: 100%">
    <div class="box-card" style="height: 100%">
      <!-- 主要内容 -->
      <div style="height: 100%;margin-bottom: 20px;">
        <div class="content_box">
          <div class="left_box">
            <div class="title_item deciveBox">设备列表</div>
            <ul class="list" id="noMon" name="monitor">
              <li v-for="(item, index) in noPreviewViewList" :key="index" class="item" @dblclick="dblclick(item, index)">
                <span v-text="item.ChannelName"></span>
                <div class="icon iconfont lebo-rightarrow"></div>
              </li>
            </ul>
          </div>
          <div class="right_box">
            <div class="title_item">
              <div style="width: 80px;">
                界面预览
                <!-- <span style="font-size: 12px; color: #bbb">（双击设备进行配置）</span> -->
              </div>
              <div class="changeBox">
                <span class="changeBoxFont">切换间隔：</span>
                <el-select popper-class="my-select" size="mini" v-model="showForm.interval_time">
                  <el-option label="不切换" value="不切换"></el-option>
                  <el-option label="5秒" value="5"></el-option>
                  <el-option label="10秒" value="10"></el-option>
                  <el-option label="30秒" value="30"></el-option>
                  <el-option label="60秒" value="60"></el-option>
                  <el-option label="120秒" value="120"></el-option>
                </el-select>
                <el-button size="mini" type="danger" @click="delAll" style="margin-right: 5px">一键清空</el-button>
                <el-button size="mini" type="primary" @click="editAll" style="margin-right: 5px">
                  恢复默认</el-button>
              </div>
            </div>
            <div id="box">
              <div class="left_top" id="monitorBox">
                <div v-for="(n, j) in equipmentTotal" :key="j" :class="['mon', setShowClass(showClass)]" name="monitor"
                  @click="monClick(n)">
                  <template v-if="monitorList.length > 0">
                    <div v-for="(item, index) in monitorList" :key="index">
                      <div v-if="n === item.monitor_Index" class="hasDevice" @click="selectVideo(index)"
                        :style="{ 'backgroundImage': activeSelect !== index ? 'url(' + require('@/assets/img/videoDeactive.png') + ')' : 'url(' + require('@/assets/img/videoActive.png') + ')' }">
                        <div class="channelNanme">
                          {{ item.ChannelName }}
                        </div>
                        <i class="icon iconfont lebo-iconclose close" @click="EditMonitor(item, index)"></i>
                      </div>

                    </div>
                    <div v-if="noMatchSetting(n)" class="noSetting"></div>
                  </template>
                  <template v-else>
                    <div class="noSetting"></div>
                  </template>
                </div>
              </div>
            </div>
            <div class="left_bottom">
              <div class="left_bottom_left">
                <i class="iconfont lebo-a-1" @click="showScreen(1)" :style="showClass == 1 ? 'color:#32c5d2;' : ''"></i>
                <i class="iconfont lebo-a-2" @click="showScreen(4)" :style="showClass == 4 ? 'color:#32c5d2;' : ''"></i>
                <i class="iconfont lebo-a-3" @click="showScreen(9)" :style="showClass == 9 ? 'color:#32c5d2;' : ''"></i>
                <i class="iconfont lebo-a-4" @click="showScreen(16)" :style="showClass == 16 ? 'color:#32c5d2;' : ''"></i>
                <i class="iconfont lebo-a-5" @click="showScreen(25)" :style="showClass == 25 ? 'color:#32c5d2;' : ''"></i>
              </div>
              <div class="left_bottom_right">
                <!-- <i class="el-icon-back" style="font-size: 26px; cursor: pointer" @click="lastPage">
                </i> -->
                <div class="backpage" @click="lastPage"></div>
                <span v-text="'第' +
                  showIndex +
                  '/' +
                  (equipmentTotal != 0
                    ? Math.ceil(equipmentTotal / showClass)
                    : 1) +
                  '屏'
                  " style="margin: 0 5px"></span>
                <div class="nextpage" @click="nextPage"></div>
                <!-- <i class="el-icon-right" style="font-size: 26px; cursor: pointer" @click="nextPage">
                </i> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <el-tabs v-model="activeName">
          <el-tab-pane label="预览配置" name="预览配置">
          </el-tab-pane> -->
        <!-- <el-tab-pane label="显示配置" name="显示配置">
            <el-form ref="showFormRef" :model="showForm" label-width="120px" style="margin-top: 30px">
              <el-form-item label="默认预览：">
                <el-select popper-class="my-select" v-model="showForm.num" @change="selectChange">
                  <el-option label="1画面" :value="1"></el-option>
                  <el-option label="4画面" :value="4"></el-option>
                  <el-option label="9画面" :value="9"></el-option>
                  <el-option label="16画面" :value="16"></el-option>
                  <el-option label="25画面" :value="25"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="切换间隔：">
                <el-select popper-class="my-select" v-model="showForm.interval_time">
                  <el-option label="不切换" value="不切换"></el-option>
                  <el-option label="5秒" value="5"></el-option>
                  <el-option label="10秒" value="10"></el-option>
                  <el-option label="30秒" value="30"></el-option>
                  <el-option label="60秒" value="60"></el-option>
                  <el-option label="120秒" value="120"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-tab-pane> -->
        <!-- </el-tabs> -->
      </div>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="previewSave" v-preventReClick>保 存
      </lbButton>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  getEquipmentList,
  getPreviewView,
  setPreviewUpdate
} from '@/api/monitoring'
import { mapMutations } from 'vuex'
export default {
  props: ['id', 'scene_name'],
  data () {
    return {
      // 默认展示的标签页
      activeName: '预览配置',
      // 显示配置菜单
      showForm: {
        num: '',
        interval_time: ''
      },
      // 已配置列表
      previewViewList: {},
      // 所有设备列表
      equipmentList: [],
      // 已配置预览设备列表
      monitorList: [],
      // 未配置设备列表
      noPreviewViewList: [],
      // 设备数量
      equipmentTotal: '',
      // 展示
      showClass: null,
      showIndex: 1,
      isClick: false,
      n: 0,
      timer: null,
      activeSelect: 0
    }
  },
  created () {
    this.previewConfig()
  },
  mounted () {
    this.monClick(1)
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    setShowClass (val) {
      switch (val) {
        case 1:
          return 'items1'
        case 4:
          return 'items4'
        case 9:
          return 'items9'
        case 16:
          return 'items16'
        case 25:
          return 'items25'
      }
    },
    // 获取预览配置
    previewConfig () {
      var that = this
      axios.all([this.fnGetEquipmentList(), this.fnGetPreviewView()]).then(
        axios.spread(function (equipment, preview) {
          console.log('所有设备---', equipment)
          console.log('预览设备----', preview)
          if (equipment.Code === 200 && preview.Code === 200) {
            // 所有的设备
            that.equipmentList =
              equipment.Data.length > 0 ? equipment.Data.reverse() : []
            that.equipmentTotal =
              equipment.Data.length > 0 ? equipment.Data.length : 0
            // 已配置数据
            that.previewViewList = preview.Data
            that.showForm.interval_time = preview.Data.is_interval
              ? preview.Data.interval_time
              : '不切换'
            that.showForm.num = preview.Data.num
            that.showClass = preview.Data.num
            // 区别未配置和已配置
            var data = preview.Data.monitor_list
            var list = that.equipmentList.concat()
            var indexList = []
            if (data.length > 1) {
              data = data.sort(that.sortrule)
            }
            console.log('排序*----', data)
            list.forEach((equipmentItem, equipmentIndex) => {
              // console.log(equipmentItem.MainId);
              data.forEach((dataItem, index) => {
                if (dataItem.mainId === equipmentItem.MainId) {
                  dataItem.ChannelName = equipmentItem.ChannelName
                  dataItem.MainId = dataItem.mainId
                  dataItem.monitor_Index = index + 1
                  // console.log(dataItem);
                  indexList.push(equipmentIndex)
                }
              })
            })
            console.log('data--', data)
            // 已配置设备
            that.monitorList = data
            if (indexList.length > 0) {
              for (var k = indexList.length - 1; k >= 0; k--) {
                list.splice(indexList[k], 1)
              }
              // 未配置
              that.noPreviewViewList = list
            } else {
              // 未配置
              if (data.length > 0) {
                that.noPreviewViewList = []
              } else {
                that.noPreviewViewList = list
              }
            }
            console.log('已配置设备---', that.monitorList)
            console.log('未配置设备---', that.noPreviewViewList)
          }
        })
      )
    },
    // 查询场景下设备列表（所有设备）
    fnGetEquipmentList () {
      //  所有设备列表
      return getEquipmentList({ SceneId: this.id })
    },
    // 获取已配置预览界面数据
    fnGetPreviewView () {
      return getPreviewView({ scene_id: this.id })
    },
    /// 关闭预览配置界面后，重置数据
    // closeViewDialog() {
    //   this.equipmentList = []
    //   this.equipmentTotal = null
    //   this.monitorList = []
    //   this.previewViewList = []
    //   this.noPreviewViewList = []
    //   this.showClass = null
    //   this.showIndex = 1
    //   this.$refs.showFormRef.resetFields()
    // },
    // 默认预览 切换时
    // selectChange(val) {
    //   // console.log(val)
    //   if (val === 1) {
    //     this.showOne()
    //   } else if (val === 4) {
    //     this.showFour()
    //   } else if (val === 9) {
    //     this.showNine()
    //   }
    // },
    // 默认预览 切换时
    selectChange (val) {
      // console.log(val)
      if (val === 1) {
        this.showScreen(1)
      } else if (val === 4) {
        this.showScreen(4)
      } else if (val === 9) {
        this.showScreen(9)
      } else if (val === 16) {
        this.showScreen(16)
      } else if (val === 25) {
        this.showScreen(25)
      }
    },
    // 设置多少屏展示
    showScreen (val) {
      this.showClass = val
      this.showForm.num = val
      this.showIndex = 1
      var monitorBox = document.getElementById('monitorBox')
      monitorBox.style.top = 0 + 'px'
      this.monClick(1)
      this.activeSelect = 0
    },
    // // 一屏展示
    // showOne() {
    //   this.showClass = 1
    //   this.showForm.num = 1
    //   this.showIndex = 1
    //   var monitorBox = document.getElementById('monitorBox')
    //   monitorBox.style.top = 0 + 'px';
    //   this.monClick(1)
    // },
    // // 四屏展示
    // showFour() {
    //   this.showClass = 4
    //   this.showForm.num = 4
    //   this.showIndex = 1
    //   var monitorBox = document.getElementById('monitorBox')
    //   monitorBox.style.top = 0 + 'px';
    //   this.monClick(1)
    // },
    // 九屏展示
    // showNine() {
    //     this.showClass = 9
    //     this.showForm.num = '9画面'
    //     this.showIndex = 1
    //     var monitorBox =  document.getElementById('monitorBox')
    //     monitorBox.style.top  = 0 + 'px';
    //      this.monClick(1)
    // },
    // 上一页
    lastPage () {
      if (this.showIndex === 1) {

      } else {
        this.showIndex--
        var h = document.getElementById('box').clientHeight
        //  // console.log(h)
        var monitorBox = document.getElementById('monitorBox')
        monitorBox.style.top = -(h * this.showIndex - h) + 'px'
        var i = this.showClass * this.showIndex - this.showClass + 1
        // console.log(i)
        this.monClick(i)
        this.activeSelect = i - 1
      }
    },
    // 下一页
    nextPage () {
      var index = Math.ceil(this.equipmentTotal / this.showClass)
      // console.log(index)
      if (this.showIndex === index) {
      } else {
        var h = document.getElementById('box').clientHeight
        // console.log(h)
        var monitorBox = document.getElementById('monitorBox')
        monitorBox.style.top = -(h * this.showIndex) + 'px'
        this.showIndex++
        var i = this.showClass * this.showIndex - this.showClass + 1
        console.log(i)
        this.monClick(i)
        this.activeSelect = i - 1
      }
    },
    // 一键清空
    delAll () {
      if (this.monitorList.length > 0) {
        this.noPreviewViewList = this.equipmentList.concat()
        this.monitorList = []
      } else {
        this.$msg.info('没有已配置界面预览的设备了')
      }
      // console.log(this.monitorList);
      // console.log(this.noPreviewViewList);
    },
    // 恢复默认
    editAll () {
      this.noPreviewViewList = []
      this.monitorList = []
      this.equipmentList.forEach((item, index) => {
        item.monitor_Index = index + 1
        this.monitorList.push(item)
      })
      //   console.log(this.monitorList)
    },
    // 去除某一个配置设备
    EditMonitor (item, index) {
      this.noPreviewViewList.push(item)
      this.monitorList.splice(index, 1)
    },
    // 选中框
    monClick (n) {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.isClick = !this.isClick
        var list = document.querySelectorAll('.mon')
        console.log(list)
        if (list.length <= 0) return
        if (this.n) {
          list[this.n - 1].classList.remove('activeIndex')
        }
        if (this.isClick) {
          list[n - 1].classList.add('activeIndex')
          this.isClick = false
        } else {
          list[n - 1].classList.remove('activeIndex')
          // this.isClick= true;
        }
        this.n = n
      }, 300)
    },
    // 双击未配置设备
    dblclick (item, index) {
      console.log('双击', item)
      // console.log(index)
      // 若选中的框已有配置的设备，则清除配置设备
      this.monitorList.forEach((item, index) => {
        if (item.monitor_Index === this.n) {
          this.noPreviewViewList.push(item)
          this.monitorList.splice(index, 1)
        }
      })
      item.monitor_Index = this.n
      this.monitorList.push(item)
      // 删除未配置中的设备
      this.noPreviewViewList.splice(index, 1)
    },
    // 双击已配置设备
    // moveMonitor(item, index) {
    //   console.log(item)
    //   console.log(index)
    //   console.log(this.n)
    //   this.timer && clearTimeout(this.timer);
    //   // 若选中框处有已配置的设备,则清除原数据
    //   for (var i = 0; i < this.monitorList.length; i++) {
    //     if (this.monitorList[i].monitor_Index === this.n) {
    //       // console.log(this.monitorList[i])
    //       this.noPreviewViewList.push({
    //         ChannelName: this.monitorList[i].ChannelName,
    //         _id: this.monitorList[i].MainId,
    //       })
    //       this.monitorList.splice(i, 1)
    //     }
    //   }
    //   // 在选中框处添加设备
    //   this.monitorList.push({
    //     monitor_Index: this.n,
    //     MainId: item.MainId,
    //     ChannelName: item.ChannelName,
    //   })
    //   // 清除的原来的数据
    //   this.monitorList.splice(index, 1)
    // },
    // 保存配置数据
    previewSave () {
      // console.log('所有设备列表---', this.equipmentList)
      // console.log('已配置数据---', this.previewViewList)
      // console.log('已配置列表---', this.monitorList)
      // console.log('未配置设备列表---', this.noPreviewViewList);
      var newArr = []
      this.monitorList.forEach((item) => {
        newArr.push({
          MainId: item.MainId,
          IsBeware: true,
          monitor_Index: item.monitor_Index
        })
      })
      var obj = {
        preview_id: this.previewViewList._id,
        num: Number(this.showForm.num),
        is_interval: this.showForm.interval_time !== '不切换',
        interval_time:
          this.showForm.interval_time === '不切换'
            ? ''
            : this.showForm.interval_time,
        monitor_info: newArr.sort(this.sortrule),
        scene_name: this.scene_name
      }
      console.log(obj)
      this.postPreviewSave(obj)
      // var data = this.equipmentList.concat()
      // var right = this.monitorList
      // var newArr = []
      // for (var i = 0; i < data.length; i++) {
      //   data[i].is_beware = false
      //   newArr = []
      //   for (var j = 0; j < right.length; j++) {
      //     // console.log(right[j])
      //     newArr.push({
      //       MainId: right[j].MainId,
      //       monitor_Index: right[j].monitor_Index
      //     })
      //     if (data[i]._id === right[j].MainId) {
      //       data[i].is_beware = true
      //     }
      //   }
      //   // console.log(data[i].is_beware)
      // }
      // this.equipmentList = data
      // // console.log(newArr)
      // var obj = {}
      // var bool = false
      // if (this.showForm.interval_time === '不切换') {
      //   bool = false
      // } else {
      //   bool = true
      // }
      // var str = this.showForm.num.substring(0, 1);
      // // console.log(str)
      // obj = {
      //   // _id: this.previewViewList._id,
      //   preview_id: this.previewViewList._id,
      //   num: Number(str),
      //   is_interval: bool,
      //   interval_time: bool ? this.showForm.interval_time : '',
      //   monitor_list: newArr.sort(this.sortrule),
      //   monitor_info: this.equipmentList,
      // }
      // this.postPreviewSave(obj)
    },
    sortrule (a, b) {
      return a.monitor_Index - b.monitor_Index
    },
    // 发起请求， 保存配置数据
    async postPreviewSave (obj) {
      const res = await setPreviewUpdate(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 选中已存在的设备
    selectVideo (index) {
      this.activeSelect = index
      console.log('index', index)
    },
    // 没有设备添加显示的图片
    noMatchSetting (num) {
      const matchSetting = this.monitorList.some(item => num === item.monitor_Index)
      return !matchSetting
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-align: left;
}

.content_box {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: start;

  /deep/ .left_box {
    .title_item {
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #000;

    }

    .deciveBox {
      color: white;
      background-color: #02A6FB;
    }

    .list {
      width: 250px;
      height: 550px;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      border: 1px solid #E6E6E6;
      padding: 10px;
      margin: 0;
      box-sizing: border-box;

      .item {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        // background-color: #fff;
        text-align: left;
        line-height: 40px;
        // background-color: #eee;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 0 10px;
        /* position: absolute; */
        /* position: relative; */
        cursor: pointer;

        .iconfont {
          font-size: 2px;
        }
      }

      .item:hover {
        background-color: #66b1ff;
        cursor: pointer;
      }
    }
  }

  /deep/ .right_box {
    width: 850px;
    margin-left: 10px;
    border: 1px solid #E6E6E6;
    padding: 10px;

    .title_item {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .changeBox {
        // text-align: end;
        display: flex;
        justify-content: end;
        align-items: center;
        .changeBoxFont{
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    #box {
      height: 484px;
      overflow: hidden;
      position: relative;

      .left_top {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: visible;
        position: absolute;
        top: 0;
        left: 0;

        .hasDevice {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          // background-image: url('../../../../../../../assets/img/videoDeactive.png');
          background-size: 40% 60%;
          background-position: center center;
          background-repeat: no-repeat;

          .channelNanme {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .noSetting {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          background-color: #FAFAFA;
          background-image: url('~@/assets/img/noMessage/videoNoSetting.png');
          background-size: 30% 60%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .activeIndex {
          border: 1px solid #32c5d2 !important;
        }

        .items1 {
          width: 100%;
          height: 472px;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          position: relative;
          margin-bottom: 2%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .items4 {
          width: 48%;
          height: 230px;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          position: relative;
          margin-bottom: 2%;
          margin-right: 1%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .items9 {
          width: 31%;
          height: 149.2px;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          position: relative;
          margin-bottom: 2%;
          margin-right: 1%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .items16 {
          width: 23%;
          height: 109px;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          position: relative;
          margin-bottom: 2%;
          margin-right: 1%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .items25 {
          width: 18%;
          height: 85px;
          padding: 0;
          margin: 0;
          border: 1px solid #ccc;
          position: relative;
          margin-bottom: 2%;
          margin-right: 1%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .close {
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 20px;
          color: #999;
          cursor: pointer;
        }
      }
    }

    .left_bottom {
      display: flex;
      flex-direction: row;
      height: 40px;
      padding: 0 1px;
      justify-content: space-between;
      align-items: center;
      // margin-right: 35%;

      .left_bottom_left {
        margin-left: 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .iconfont {
          font-size: 40px;
          cursor: pointer;
          margin-left: 5px;
        }
      }

      .left_bottom_right {
        margin-right: 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 26px;
        line-height: 26px;

        .backpage {
          width: 26px;
          height: 26px;
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('~@/assets/img/ychj/backarrowicon.png');
        }

        .nextpage {
          width: 26px;
          height: 26px;
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('~@/assets/img/ychj/nextarrowicon.png');
        }
      }
    }
  }
}

// .view_dialog {
//   /deep/ .el-dialog {
//     margin-top: 9vh !important;
//     height: 85vh;
//     .el-dialog__body {
//       height: 80%;
//       padding: 20px;
//       .el-tabs,
//       .el-tabs__content,
//       .el-row,
//       .el-col {
//         height: 100%;
//         .list {
//           /* width: 100%; */
//           height: calc(100% - 40px);
//           overflow: hidden;
//           overflow-y: auto;
//           display: flex;
//           flex-direction: column;
//           border: 1px solid #ccc;
//           padding: 0;
//           margin: 0;
//           .item {
//             width: 100%;
//             height: 40px;
//             background-color: #fff;
//             text-align: center;
//             line-height: 40px;
//             background-color: #eee;
//             margin-bottom: 5px;
//             /* position: absolute; */
//             /* position: relative; */
//             cursor: pointer;
//           }
//           .item:hover {
//             background-color: #66b1ff;
//             cursor: pointer;
//           }
//         }
//         #box {
//
//       }
//     }
//   }
// }
.el-tabs {
  /deep/ .el-tabs__content {
    overflow: inherit !important;
  }

  /deep/ .el-tabs__item {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1.5px;
    background-color: #f2f2f2;
  }

  /deep/ .el-tabs__active-bar {
    height: 1.5px;
  }

  /deep/ .el-tabs__item {
    border-right: none !important;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  .checkButton2 {
    position: absolute;
    right: 110px;
    bottom: 0;

  }
}

/deep/.el-select {
  width: 30%;
  margin-right: 30px;
}</style>
